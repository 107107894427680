<template>
    <div>
        <div v-show="value.length === 0" class="alert alert-primary text-center mb-5">
            {{$t('references.emptyReferences')}}
        </div>

        <div class="mb-4">
            <div class="list-group mb-3 references-list" v-show="value.length !== 0">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
                            <strong>{{$t('references.languagePair')}}</strong>
                        </div>
                        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
                            <strong>{{$t('references.services')}}</strong>
                        </div>
                        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
                            <strong>{{$t('references.specializations')}}</strong>
                        </div>
                        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
                            <strong>{{$t('references.forms')}}</strong>
                        </div>
                        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
                            <strong>{{$t('references.amount')}}</strong>
                        </div>
                    </div>
                </div>
                <div class="list-group-item" v-for="(reference, index) in references" :key="`reference-${index}`">
                    <references-list-item
                        :reference="reference"
                        @remove="onRemove(index)"
                        @edit="onEdit(index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReferencesListItem from './ReferencesListItem.vue'
export default {
    components: { ReferencesListItem },
    name: 'ReferencesList',
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    computed: {
        references: {
            get () {
                const state = this.$store.state.userData
                const documentsList = (state.documentsList && state.documentsList.documents && Array.isArray(state.documentsList.documents)) ? state.documentsList.documents : []
                return this.value.map(item => {
                    return { ...item, numberOfAttachments: documentsList.filter(entry => entry.tempId === item.tempId).length }
                })
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        specializationOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.specializationOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        serviceOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.serviceOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        amountUnitOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.amountUnitOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        }
    },
    methods: {
        onRemove (index) {
            this.references = this.references.filter((item, itemIndex) => itemIndex !== index)
        },
        onEdit (index) {
            this.$emit('edit', index)
        }
    }
}
</script>

<style lang="scss">
.references-list .list-group-item {
    letter-spacing: unset;
    font-weight: 400;
    text-transform: none;
}
</style>

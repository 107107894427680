<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <multiselect-editor
            v-model="fromLanguage"
            :id="'references.fromLanguage'"
            :validation="$v.fromLanguage"
            :options="languageOptions"
            :label="$t('references.fromLanguage')"
            :min-items="1"
        />

        <multiselect-editor
            v-model="toLanguage"
            :id="'references.toLanguage'"
            :validation="$v.toLanguage"
            :options="languageOptions"
            :label="$t('references.toLanguage')"
            :min-items="1"
        />

        <multiselect-editor
            v-model="services"
            :id="'references.services'"
            :heading="$t('references.services')"
            :validation="$v.services"
            :options="serviceOptions"
            :label="$t('references.servicesHint')"
            :min-items="1"
        />

        <multiselect-editor
            class="mt-4"
            v-model="specializations"
            :id="'references.specializations'"
            :heading="$t('references.specializations')"
            :validation="$v.specializations"
            :options="specializationOptions"
            :label="$t('references.specializationsHint')"
            :min-items="1"
        />

        <multiselect-editor
            class="mt-4"
            v-model="forms"
            :id="'references.forms'"
            :heading="$t('references.forms')"
            :options="formOptions"
            :validation="$v.forms"
            :label="$t('references.formsHint')"
            :min-items="1"
        />

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="amount"
                    :id="'references.amount'"
                    :label="$t('references.amount')"
                    :validation="$v.amount"
                    :formatter="formatNumber"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input-select
                    v-model="amountUnit"
                    :id="'references.amountUnit'"
                    :label="$t('references.amountUnit')"
                    :validation="$v.amountUnit"
                    :options="amountUnitOptions"
                />
            </div>
        </div>

        <p class="text-aspena-blue font-weight-bold mt-4">{{$t('references.uploadsLabel')}}</p>
        <div class="p-4 bg-white mb-5">
            <uploader-box
                :uploader-name="'file'"
                :url="url"
                :multi="true"
                @uploaded="onUploaded"
            />
        </div>
        <documents-list
            v-model="localDocuments"
            :disable-form="true"
            :disable-name-change="true"
            :show-references="true"
        >
            <template #errors>
                <div v-if="$v.localDocuments.$invalid" class="alert alert-danger text-center">
                    {{$t('references.uploadAtLeastOneFile')}}
                </div>
            </template>
        </documents-list>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('references.referenceCancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t((editedReference !== null) ? 'references.referenceSave' : 'references.referenceSubmit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { Reference, Document, DocumentsList } from '../../../DTO'
import { required, numeric } from 'vuelidate/lib/validators'
import MultiselectEditor from '../../PropertiesListsPage/Components/MultiselectEditor.vue'
import { nanoid } from 'nanoid'
import UploaderBox from '../../DocumentsPage/Components/UploaderBox.vue'
import DocumentsListComponent from '../../DocumentsPage/Components/DocumentsList.vue'

export default {
    name: 'PropertiesListForm',
    components: {
        MultiselectEditor,
        UploaderBox,
        DocumentsList: DocumentsListComponent
    },
    props: {
        editedReference: {
            type: Object,
            required: false,
            default: () => null
        }
    },
    data () {
        let tempId = nanoid(10)
        if (!this.$store.state.referenceTempId) {
            this.$store.commit('setReferenceTempId', tempId)
        } else {
            tempId = `${this.$store.state.referenceTempId}`
        }
        if (this.editedReference !== null) {
            tempId = `${this.editedReference.tempId}`
            this.$store.commit('setReferenceTempId', tempId)
        }
        return {
            ...((new Reference({ tempId: tempId })).toObject()),
            ...((this.editedReference !== null) ? this.editedReference : {})
        }
    },
    computed: {
        localDocuments: {
            get () {
                return this.documents.filter(item => item.tempId === this.tempId)
            },
            set (value) {
                const state = JSON.parse(JSON.stringify(this.$store.state.userData.documentsList || { documents: [] }))
                if (Array.isArray(state.documents)) {
                    state.documents = state.documents.filter(item => item.tempId !== this.tempId)
                } else {
                    state.documents = []
                }
                const data = new DocumentsList({ ...state, documents: [...value, ...state.documents] })
                this.$store.commit('mergeData', data)
            }
        },
        documents: {
            get () {
                const state = this.$store.state.userData
                if (state.documentsList && state.documentsList.documents) {
                    return state.documentsList.documents
                }
                return []
            },
            set (value) {
                const state = this.$store.state.userData.documentsList || { documents: [] }
                const data = new DocumentsList({ ...state, documents: [...value, ...state.documents] })
                this.$store.commit('mergeData', data)
            }
        },
        url () {
            return `${this.$api.uploads.uploadUrl}?type=referenceDocuments&tempId=${this.tempId}`
        },
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        specializationOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.specializationOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        formOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.formOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        serviceOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.serviceOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        amountUnitOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.amountUnitOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        }
    },
    methods: {
        onUploaded (data) {
            const documents = JSON.parse(JSON.stringify(this.localDocuments))
            if (data.serverId) {
                const document = JSON.parse(data.serverId)
                if (document.id) {
                    documents.push(new Document(document))
                    this.localDocuments = documents
                }
            }
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('references.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new Reference(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('setReferenceTempId', nanoid(10))
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        },
        formatNumber (value) {
            return `${value}`.replace(/\D/ig, '')
        }
    },
    validations () {
        return {
            toLanguage: {
                required
            },
            fromLanguage: {
                required
            },
            services: {
                required
            },
            forms: {
                required
            },
            specializations: {
                required
            },
            amount: {
                required,
                numeric
            },
            amountUnit: {
                required
            },
            localDocuments: {
                required
            }
        }
    }
}
</script>

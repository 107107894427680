<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{(type === 'company') ? $t('references.agencyTitle') : $t('references.title')}}</h1>
        <div class="mb-4" v-show="!showForm">
            <aspena-btn
                variant="aspena-orange"
                @click.prevent="onAddNew"
            >
                {{$t('references.add')}}
            </aspena-btn>

            <p class="mt-4">{{$t('references.addHint')}}</p>
        </div>

        <references-list
            v-model="references"
            v-show="!showForm"
            @edit="onEdit"
        />

        <div
            v-if="showForm"
            class="p-4 bg-light"
        >
            <h4 class="mb-3">{{$t('references.formTitle')}}</h4>
            <p class="mb-4">
                {{$t('references.formSubtitle')}}
            </p>
            <reference-form
                :edited-reference="editedReference"
                @submit="onAdd"
                @cancel="showForm = false"
            />
        </div>

        <div class="mt-5 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('references.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                @click.prevent="onSubmit"
            >
                {{$t((references.length === 0) ? 'references.skipAndSubmit' : 'references.submit')}}
            </aspena-btn>
        </div>
    </div>
</template>

<script>
import { ReferenceList } from '../../DTO'
import ReferenceForm from './Components/ReferenceForm.vue'
import ReferencesList from './Components/ReferencesList.vue'

export default {
    name: 'ReferencesPage',
    components: {
        ReferenceForm,
        ReferencesList
    },
    data () {
        return {
            showForm: false,
            editedIndex: null
        }
    },
    computed: {
        type () {
            return this.$store.state.userData.basicData.type
        },
        references: {
            get () {
                return this.$store.state.userData.referenceList || []
            },
            set (value) {
                const data = new ReferenceList(JSON.parse(JSON.stringify(value)))
                this.$store.commit('mergeData', data)
            }
        },
        editedReference: {
            get () {
                return (this.editedIndex !== null && this.references[this.editedIndex]) ? this.references[this.editedIndex] : null
            },
            set (value) {
                const list = JSON.parse(JSON.stringify(this.references)).map((item, index) => {
                    return (index === this.editedIndex) ? value : item
                })
                this.references = list
            }
        }
    },
    mounted () {
        if (this.references.length === 0) {
            this.showForm = true
        }
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onEdit (index) {
            this.showForm = false
            this.editedIndex = null
            this.$nextTick(() => {
                this.editedIndex = index
                this.showForm = true
            })
        },
        onAdd (reference) {
            if (this.editedReference !== null) {
                this.editedReference = reference
            } else {
                const references = [...this.references]
                references.push(reference)
                this.references = references
            }

            this.$nextTick(() => {
                this.editedIndex = null
                this.showForm = false
            })
        },
        onAddNew () {
            this.editedIndex = null
            this.showForm = false
            this.$store.commit('setReferenceTempId', null)

            this.$nextTick(() => {
                this.showForm = true
            })
        },
        onSubmit () {
            this.$router.push({ name: (this.type === 'company') ? 'workHours' : 'documents' })
        },
        onCancel () {
            this.$router.push({ name: 'propertyLists' })
        }
    }
}
</script>

<template>
    <div class="row align-items-center align-content-center">
        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
            <p class="mb-1">
                {{fromLanguageText}}
            </p>
            <p class="mb-1">
                <b-icon class="text-aspena-blue" icon="arrow-right"/>
            </p>
            <p class="mb-0">
                {{toLanguageText}}
            </p>
            <small>{{$t('references.numberOfAttachments')}} {{reference.numberOfAttachments}}</small>
        </div>
        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
            {{servicesTexts.join(', ')}}
        </div>
        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
            {{specializationsTexts.join(', ')}}
        </div>
        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
            {{formsTexts.join(', ')}}
        </div>
        <div class="col-12 mb-2 mb-lg-0 col-md-6 col-lg-2">
            {{reference.amount}} {{amountUnitText}}
        </div>
        <div class="col-12 mb-2 mb-lg-0 text-md-right col-md-6 col-lg-2 align-self-center whitespace-nowrap">
            <aspena-btn
                size="sm"
                variant="primary"
                @click.prevent="onEdit"
                class="mr-2"
                :hint="$t('buttonEditHint')"
            >
                <b-icon icon="pencil"/>
            </aspena-btn>

            <aspena-btn
                size="sm"
                variant="danger"
                @click.prevent="onRemove"
                :hint="$t('buttonRemoveHint')"
            >
                <b-icon icon="trash"/>
            </aspena-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReferencesListItem',
    props: {
        reference: {
            type: Object,
            required: true
        }
    },
    computed: {
        fromLanguageText () {
            const entry = this.languageOptions.filter(item => this.reference.fromLanguage.includes(`${item.value}`)).map(item => item.text)
            return entry.join(', ')
        },
        toLanguageText () {
            const entry = this.languageOptions.filter(item => this.reference.toLanguage.includes(`${item.value}`)).map(item => item.text)
            return entry.join(', ')
        },
        amountUnitText () {
            const entry = this.amountUnitOptions.filter(item => `${item.value}` === `${this.reference.amountUnit}`)
            if (entry.length > 0) {
                return entry[0].text
            }
            return this.reference.amountUnit
        },
        servicesTexts () {
            return this.reference.services.map(this.enumListToTexts(this.serviceOptions))
        },
        specializationsTexts () {
            return this.reference.specializations.map(this.enumListToTexts(this.specializationOptions))
        },
        formsTexts () {
            return this.reference.forms.map(this.enumListToTexts(this.formOptions))
        },
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            return options
        },
        specializationOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.specializationOptions || []))
            return options
        },
        serviceOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.serviceOptions || []))
            return options
        },
        amountUnitOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.amountUnitOptions || []))
            return options
        },
        formOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.formOptions || []))
            return options
        }
    },
    methods: {
        enumListToTexts (enumList) {
            return (item) => {
                const entry = enumList.filter(entry => `${entry.value}` === `${item}`)
                if (entry.length > 0) {
                    return entry[0].text
                }
                return item
            }
        },
        onRemove () {
            this.$emit('remove')
        },
        onEdit () {
            this.$emit('edit')
        }
    }
}
</script>
